import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

export default function DualInput(props) {
  const {
    monthDays,
    yearMonths,
    lakhThousands,
    label,
    onChange,
  } = props;
  const [error, setError] = React.useState(true);
  const [helperText, setHelperText] = React.useState(['', '']);
  const [value, setValue] = React.useState([0, 0]);
  const [selects, setSelects] = React.useState([[], []]);

  useEffect(() => {
    const years = [];
    let i = 0;
    for (i = 0; i < 35; i += 1) {
      years.push(i);
    }
    const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    const days = [];
    for (i = 0; i < 30; i += 1) {
      days.push(i);
    }
    const lakhs = [];
    for (i = 0; i < 100; i += 1) {
      lakhs.push(i);
    }
    const thousands = [];
    for (i = 0; i < 100; i += 10) {
      thousands.push(i);
    }

    if (monthDays) {
      setHelperText(['Months', 'Days']);
      setSelects([months, days]);
    } else if (yearMonths) {
      setHelperText(['Years', 'Months']);
      setSelects([years, months]);
    } else { // lakhThousands
      setHelperText(['Lakhs', 'Thousands']);
      setSelects([lakhs, thousands]);
    }
  }, [monthDays, yearMonths]);

  const handleChange = (high, low) => {
    if (!onChange) return null;
    if (monthDays) {
      return onChange(high * 30 + low);
    }
    if (yearMonths) {
      return onChange(high * 12 + low);
    }
    // lakhThousands
    return onChange(high * 100 + low);
  };

  return (
    <Grid container direction="column">
      <Grid container direction="row" style={{ marginBottom: 0 }}>
        <FormControl variant="outlined" size="small" style={{ marginRight: 10 }}>
          <InputLabel id="demo-customized-select-label">{helperText[0]}</InputLabel>
          <Select
            labelId="demo-customized-select-label"
            id="demo-customized-select"
            value={value[0]}
            onChange={(e) => {
              const v = [...value];
              v[0] = e.target.value;
              setValue(v);
              handleChange(v[0], v[1]);
            }}
          >
            {selects[0].map((x) => <MenuItem key={x} value={x}>{x}</MenuItem>)}
          </Select>
        </FormControl>
        <FormControl variant="outlined" size="small">
          <InputLabel id="demo-customized-select-label">{helperText[1]}</InputLabel>
          <Select
            labelId="demo-customized-select-label"
            id="demo-customized-select"
            value={value[1]}
            onChange={(e) => {
              const v = [...value];
              v[1] = e.target.value;
              setValue(v);
              handleChange(v[0], v[1]);
            }}
          >
            {selects[1].map((x) => <MenuItem key={x} value={x}>{x}</MenuItem>)}
          </Select>
        </FormControl>
      </Grid>
      <p style={{ marginTop: 4, marginLeft: 14, color: '#00000082', fontSize: '0.75rem' }}>{label}</p>
    </Grid>
  );
}
