import React from 'react';
import TextField from '@material-ui/core/TextField';

export default function NameInput(props) {
  var { label, helperText, required } = props;
  const [error, setError] = React.useState(true);
  const isValidName = (name) => {
    var re = /^[a-zA-Z ]{2,30}$/;
    return re.test(String(name));
  };

  helperText = helperText ? helperText : "Candidate's name";
  label = label ? label : "name";

  return (
    <TextField required={required} label={label} type="text"
               variant="outlined" size="small"
               error={error} helperText={helperText}
               onChange={e => {
                 const valid = isValidName(e.target.value);
                 setError(!valid);
                 props.retVal && props.retVal({error: !valid, value: e.target.value});
               }}
    />
  );
}
