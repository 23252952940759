/* eslint-disable no-console, react/jsx-props-no-spreading, react/prop-types,
   no-param-reassign, no-shadow, no-use-before-define */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import { useSnackbar } from 'notistack';
import ExcelJS from 'exceljs';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField';
import Badge from '@material-ui/core/Badge';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fade from '@material-ui/core/Fade';
import Autocomplete from '@material-ui/lab/Autocomplete';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
// import MessageIcon from '@material-ui/icons/Message';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import * as Utils from '../utils';

// import 'react-phone-number-input/style.css';

import HistoryWindow from './HistoryWindow';
import TeamAvatars from './TeamAvatars';
import FunnelTransition from './FunnelTransition';
import CandidateDetails from './CandidateDetails';
import getProfileStates from '../profileStates';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formControl: {
    minWidth: 130,
    marginTop: 10,
  },
  fixedHeight: {
    height: 240,
  },
}));

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      // maxWidth: 40,
      width: '100%',
      backgroundColor: '#37c871',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    color: '#4d4d4d',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(0),
    '&:hover': {
      color: '#37c871e0',
    },
    '&$selected': {
      color: '#37c871ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#2c7844',
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const defaultHistory = {
  setHistory: false,
  row: null,
};

function Profiles(props) {
  const { companies, jds, roles } = props;
  const { orgId } = global.role;
  const colRef = window.firestore.collection('data').doc(orgId).collection('profiles');
  const histRef = window.firestore.collection('data').doc(orgId).collection('history');
  const classes = useStyles();
  const [tabIndex, setTabIndex] = React.useState(0); // Tab index
  const [ProfileStates] = React.useState(getProfileStates());
  const [showModal, setShowModal] = React.useState(false);
  const [row, setRow] = React.useState(null);
  const [val, setVal] = React.useState({});
  const [users, setUsers] = React.useState({});
  const [history, setHistory] = React.useState(defaultHistory);
  const [openAssign, setOpenAssign] = React.useState(false);
  const [assignee, setAssignee] = React.useState('');

  const { enqueueSnackbar } = useSnackbar();
  const storageRef = window.storage.ref();
  const today = new Date();
  const lastWeek = Math.floor((new Date()).setDate(today.getDate() - 7));

  // setColumns(getTableColumns());

  useEffect(() => {
    const roles = props.roles ? props.roles : [];
    const u = {};
    roles.forEach((r) => {
      u[r.appUid] = { name: r.email, teams: r.teams };
    });
    setUsers(u);
  }, [roles ? roles.length : 0, companies]);

  const handleTab = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const getResumeUrl = (row) => {
    if (!row.resumes || row.resumes.length === 0) {
      return '';
    }
    if (row.resumeUrl) {
      return row.resumeUrl;
    }
    const entry = row.resumes[row.resumes.length - 1];
    const fileRef = storageRef.child(entry.object);
    fileRef
      .getDownloadURL()
      .then((url) => {
        colRef
          .doc(row.profileId)
          .set({ resumeUrl: url }, { merge: true });
      })
      .catch((err) => {
        console.log(err);
        return '';
      });
    return '';
  };

  const exportExcel = async (columns, data) => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'Myndrix auto generator';
    workbook.views = [
      {
        x: 0,
        y: 0,
        width: 10000,
        height: 20000,
        firstSheet: 0,
        activeTab: 1,
        visibility: 'visible',
      },
    ];
    const sheet = workbook.addWorksheet('Attendance', {
      headerFooter: {
        firstHeader: '',
        firstFooter: '',
      },
    });
    // Set Frozen View - ie. top 1 rows dont scroll
    sheet.views = [
      { state: 'frozen', xSplit: 0, ySplit: 1 },
    ];
    // Set column widths
    const cols = [
      { header: 'Name', key: 'name', width: 28 },
      { header: 'Phone', key: 'phone', width: 18 },
      { header: 'Email', key: 'email', width: 25 },
      { header: 'Company', key: 'company', width: 18 },
      { header: 'Role', key: 'role', width: 24 },
      { header: 'Experience', key: 'experience', width: 8 },
      { header: 'CTC', key: 'ctc', width: 8 },
      { header: 'Notice', key: 'notice', width: 8 },
      { header: 'Qualification', key: 'qualification', width: 12 },
      { header: 'Year of Passing', key: 'yop', width: 8 },
      { header: 'Stage', key: 'stage', width: 12 },
      { header: 'Resume', key: 'resume', width: 20 },
    ];
    sheet.columns = cols;

    data.forEach((x, idx) => {
      const row = sheet.getRow(idx + 2);
      const url = getResumeUrl(x);
      row.values = [
        x.name,
        x.phone,
        x.email,
        x.company,
        x.role,
        `${Math.floor(x.experience / 12)}Y ${x.experience - Math.floor(x.experience / 12) * 12}M`,
        x.ctc / 100,
        x.notice,
        x.qualification,
        (new Date(x.yearPassing)).getFullYear(),
        x.state,
        url,
      ];
    });

    workbook.xlsx.writeBuffer().then((data) => {
      const today = new Date();
      const blob = new Blob(
        [data],
        { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
      );
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const appendMessage = (text, profileId, extra, state) => {
    extra = extra || '';
    const msg = {
      time: Utils.timestamp(),
      authorId: global.role.appUid,
      authorOrg: global.role.orgId,
      message: text,
      uploaded: extra,
      state,
    };
    histRef.doc(profileId).set({ [msg.time]: msg }, { merge: true });
  };

  const appendHistory = (rec, com) => {
    const pid = rec.origProfileId ? rec.origProfileId : rec.profileId;
    const state = com.state ? com.state : rec.state;
    appendMessage(com.history, pid, null, state);
  };

  /*
  const getProfileId = (email, phone) => {
    // Search for email or phone
    // TODO: use backend API to search as this user may not have access to
    //       all records
  };
  */

  const renderModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={showModal}>
        <div className={classes.modalPaper}>
          <FunnelTransition
            val={val}
            row={row}
            setVal={setVal}
            funnelStates={ProfileStates}
            curState={ProfileStates[tabIndex]}
            onComplete={() => {
              setShowModal(false);
            }}
          />
        </div>
      </Fade>
    </Modal>
  );

  const renderAssignDialog = () => {
    const assignees = props.roles ? props.roles.filter((x) => x.isRegular && x.email !== 'ajay@myndrix.com') : [];
    return (
      <Dialog
        open={openAssign}
        onClose={() => setOpenAssign(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Reassign Profile</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the email id of the person you want to assign this profile
          </DialogContentText>
          <Autocomplete
            id="select-assignee"
            autoSelect
            options={assignees.map((x) => ({ title: x.email }))}
            getOptionLabel={(option) => option.title}
            onInputChange={(e, v) => setAssignee(v)}
            style={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Email Address"
                margin="dense"
                type="email"
                fullWidth
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenAssign(false);
              setAssignee('');
              setRow(null);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (!assignee) {
                enqueueSnackbar('No person selected to assign', { variant: 'error' });
              } else {
                const found = props.roles.find((u) => u.email === assignee);
                colRef.doc(row.profileId)
                  .set({ ownUid: found.appUid, modifiedDate: Utils.timestamp() }, { merge: true })
                  .then(() => {
                    const msg = `${row.name}'s profile reassigned to ${assignee}`;
                    enqueueSnackbar(msg, { variant: 'success' });
                    appendHistory(row, { history: `--> reassign; ${msg}` });
                  })
                  .catch((err) => enqueueSnackbar(err, { variant: 'error' }));
              }
              setOpenAssign(false);
              setAssignee('');
              setRow(null);
            }}
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const generateComments = (oldRec, newRec) => {
    const o = oldRec;
    const n = newRec;
    const newState = n.state;
    let history = `--> ${newState}; `;
    let comment = o.comment ? o.comment : '';
    if (newState === 'inbox') {
      comment = `Added profile for ${n.name}; `;
      history += `${comment}; ${n.phone}; ${n.email};`;
    } else if (newState === 'shortlisted') {
      comment = `for ${n.company}`;
      comment += ` for role: ${n.role}.`;
      history += `${comment};`;
    } else if (newState === 'scheduled') {
      const d = new Date(n.scheduledDate);
      comment = `${n.company} interview on ${Utils.printDate(d)}`;
      comment += ` for role: ${n.role}.`;
      history += `${comment};`;
    } else if (newState === 'interviewed') {
      history += `${comment}; ${o.company}; ${o.role};`;
    } else if (newState === 'selected') {
      history += `${comment}; ${o.company}; ${o.role};`;
    } else if (newState === 'rejected') {
      comment = n.rejectReason;
      history += `${comment}; ${o.company}; ${o.role};`;
    } else if (newState === 'offered') {
      const d = new Date(n.offerDate);
      const j = new Date(n.joiningDate);
      comment = `${o.company} offer on ${Utils.printDate(d)}`;
      comment += `. Joining date is ${Utils.printDate(j)}`;
      history += `${comment}; ${o.company}; ${o.role};`;
    } else if (newState === 'joined') {
      const d = new Date(n.joinDate);
      comment = `Joined ${o.company} on ${Utils.printDate(d)}`;
      comment += `. CTC is ${n.newCtc}`;
      comment += ` lakhs and role is ${n.newRole}.`;
      history += `${comment} ;`;
    } else if (newState === 'dropped') {
      comment = n.droppedReason;
      history += `${comment}; ${o.company}; ${o.role};`;
    } else if (newState === 'unsuitable') {
      comment = n.reason;
      history += `${comment}; ${o.company}; ${o.role};`;
    } else if (newState === 'uninterested') {
      comment = n.reason;
      history += `${comment}; ${o.company}; ${o.role};`;
    }
    return { comment, history, state: newState };
  };

  const isSameTeam = (profile) => {
    if (!global.role.isManager && !global.role.isAdmin) return true;
    const uteams = users[profile.ownUid] ? users[profile.ownUid].teams : null;
    if (!uteams) return true;
    let found = false;
    uteams.forEach((t) => {
      if (global.role.teams && global.role.teams.find((gt) => gt === t)) {
        found = true;
        // console.log('found: ', users[profile.ownUid].name + ' ', );
      }
    });
    return found;
  };

  const getTableColumns = () => {
    const currentState = ProfileStates[tabIndex].title;
    const cols = [
      // 0
      {
        title: 'Name',
        field: 'modName',
      },
      // 1
      {
        title: 'Company',
        field: 'company',
        render: (rowData) => {
          const { company } = rowData;
          return (
            <span>
              { company || 'Not Processed' }
            </span>
          );
        },
      },
      // 2
      {
        title: 'Role',
        field: 'role',
        render: (rowData) => {
          const { role } = rowData;
          return (
            <span>
              { role || '' }
            </span>
          );
        },
      },
      // 2
      {
        title: 'Recruiter',
        field: 'userName',
        width: '10em',
        render: (rowData) => {
          const { teams, userName } = rowData;
          return (
            <div>
              <TeamAvatars teams={teams} />
              {userName}
            </div>
          );
        },
      },
      // 3
      // { title: 'Experience/CTCNotice', field: 'details'},
      {
        title: 'Comments',
        field: 'comment',
        render: (rowData) => (
          <div>
            {rowData.comment}
            {rowData.modifiedDate < lastWeek && (
              <div style={{ fontSize: 'x-small', color: '#d40000' }}>
                {`Updated: ${Utils.printDate(new Date(rowData.modifiedDate))}`}
              </div>
            )}
            {rowData.modifiedDate >= lastWeek && (
              <div style={{ fontSize: 'x-small' }}>
                {`Updated: ${Utils.printDate(new Date(rowData.modifiedDate))}`}
              </div>
            )}
          </div>
        ),
      },
    ];
    // Add updated field for inbox state
    if (currentState === 'inbox') {
      cols.splice(2, 0, { title: 'Added on', field: 'addedOn' });
    }
    if (currentState === 'all') {
      cols.splice(1, 0, {
        title: 'Stage',
        field: 'state',
        render: (rowData) => {
          const { state } = rowData;
          const prof = ProfileStates.find((x) => x.title === rowData.state);
          const color = prof ? prof.color : '#808080';
          return (
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              style={{
                borderColor: color,
                borderWidth: 1,
                borderStyle: 'solid',
                borderRadius: 5,
                background: `${color}40`,
              }}
            >
              {state}
            </Grid>
          );
        },
      });
    }
    // const r = memoize(cols);
    // console.log('returning: ', r);
    return cols;
  };

  const renderProfileTable = () => {
    const currentState = ProfileStates[tabIndex].title;
    const { profiles } = props;
    // Show only profiles in user selected state
    let d0 = [];
    if (currentState !== 'all') {
      d0 = profiles ? profiles.filter((x) => x.state === currentState) : [];
    } else {
      d0 = profiles;
    }
    // Show only profiles from same team
    const data = d0.filter((x) => isSameTeam(x));
    data.forEach((x) => {
      // Show company in name column
      if (x.company && !x.addedCompany) {
        x.modName = `${x.name} (${x.company}, ${x.role}) `;
        x.addedCompany = true;
      } else {
        x.modName = x.name;
      }

      // Merge columns
      x.details = `${Utils.months2words(x.experience)} / ${Utils.lakhs2words(x.ctc)} / ${Utils.days2words(x.notice)}`;

      // Show user name
      x.userName = users[x.ownUid] ? users[x.ownUid].name.substring(0, users[x.ownUid].name.indexOf('@')) : '';
      x.teams = users[x.ownUid] ? users[x.ownUid].teams : [];

      // Add 'addedOn' field for inbox
      if (currentState === 'inbox') {
        const d = x.createdDate ? new Date(x.createdDate) : new Date(2020, 2, 19);
        const month = Utils.pad2(d.getMonth() + 1);
        x.addedOn = `${d.getFullYear()}-${month}-${Utils.pad2(d.getDate())}`;
      }
    });
    // Filter by modified time
    d0 = profiles.sort((a, b) => {
      if (a.modifiedDate && b.modifiedDate) {
        return a.modifiedDate < b.modifiedDate ? 1 : -1;
      } if (a.modifiedDate && !b.modifiedDate) {
        return -1;
      } if (!a.modifiedDate && b.modifiedDate) {
        return 1;
      } if (a.createdDate && !b.createdDate) {
        return -1;
      } if (!a.createdDate && b.createdDate) {
        return 1;
      }
      return a.createdDate < b.createdDate ? 1 : -1;
    });

    const actions = [
      {
        icon: () => <DoubleArrowIcon style={{ color: '#808080', fontSize: 21 }} />,
        tooltip: 'Process this profile',
        onClick: (e, r) => {
          setShowModal(true);
          setRow(r);
        },
      },
      {
        icon: () => <CloudDownloadIcon style={{ color: '#808080', fontSize: 21 }} />,
        tooltip: 'Download resume',
        onClick: (e, r) => {
          if (!r.resumes || r.resumes.length === 0) {
            enqueueSnackbar(`No resume for ${r.name}'s profile`, { variant: 'error' });
          } else {
            const entry = r.resumes[r.resumes.length - 1];
            if (r.resumeUrl) {
              Utils.downloadNewWindow(r.resumeUrl, entry.name);
            } else {
              const fileRef = storageRef.child(entry.object);
              fileRef
                .getDownloadURL()
                .then((url) => {
                  Utils.downloadNewWindow(url, entry.name);
                  colRef
                    .doc(r.profileId)
                    .set({ resumeUrl: url }, { merge: true });
                })
                .catch((err) => {
                  enqueueSnackbar(err.message, { variant: 'error' });
                });
            }
          }
        },
      },
    ];
    if (currentState === 'all') {
      actions.splice(0, 1);
    }
    if (global.role && global.role.isManager) {
      actions.push({
        icon: () => <ArrowForwardIosIcon style={{ color: '#808080', fontSize: 21 }} />,
        tooltip: 'Reassign profile',
        onClick: (e, r) => {
          setRow(r);
          setOpenAssign(true);
        },
      });
    }

    const options = {
      headerStyle: {
        backgroundColor: '#afe9c6e0',
      },
      exportButton: true,
      exportCsv: exportExcel,
      filtering: true,
      padding: 'dense',
      pageSizeOptions: [10, 20, 40],
      pageSize: 20,
    };
    const rowAction = (event, rdata) => {
      // enqueueSnackbar('Row clicked ' + rdata.name, {variant: 'info'});
      setHistory({ show: true, row: rdata });
    };

    return (
      <div>
        <MaterialTable
          title=""
          columns={getTableColumns()}
          data={data}
          actions={actions}
          options={options}
          isLoading={false}
          onRowClick={rowAction}
        />
      </div>
    );
  };

  const renderTabs = () => {
    const pc = props.profileCount;
    return (
      <Grid item xs={12}>
        <Paper>
          <StyledTabs
            value={tabIndex}
            onChange={handleTab}
            variant="scrollable"
            scrollButtons="auto"
          >
            {ProfileStates
              .filter((x) => ((x.title === 'invoiced' && !global.role.isManager) || true))
              .map((x) => (
                <StyledTab
                  label={<Badge badgeContent={pc[x.title]}>{x.title.toUpperCase()}</Badge>}
                  key={x.title}
                />
              ))}
          </StyledTabs>
          {renderProfileTable()}
        </Paper>
      </Grid>
    );
  };

  const renderHistoryWindow = () => {
    const h = Utils.deepCopy(history);
    return (
      <HistoryWindow
        disabled={!h.show}
        row={h.row}
        users={users}
        onClose={() => {
          h.show = false;
          setHistory(h);
        }}
      />
    );
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={3}>
        <CandidateDetails
          generateComments={generateComments}
          appendHistory={appendHistory}
          companies={companies}
          jds={jds}
        />
        { renderTabs() }
        { renderModal() }
        { renderAssignDialog() }
        { renderHistoryWindow() }
      </Grid>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  profiles: state.firestore.profiles,
  roles: state.firestore.roles,
  companies: state.firestore.companies,
  jds: state.firestore.jds,
  profileCount: state.firestore.profileCount,
});

export default connect(mapStateToProps)(Profiles);
