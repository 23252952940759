import React, { useEffect } from 'react';

// import { makeStyles, withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';

import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';

export default function EditableText(props) {
  const { text, onSave, width } = props;
  const [editing, setEditing] = React.useState(false);
  const [content, setContent] = React.useState('');

  useEffect(() => {
    setContent(text);
  }, [text]);

  return (
    <div>
      {!editing && (
        <Grid container direction="row">
          <div
            style={{
              minHeight: 40,
              minWidth: width || 400,
              backgroundColor: '#f2f2f2',
              padding: 5,
              borderRadius: 3,
              margin: 3,
            }}
          >
            {content}
          </div>
          <IconButton
            style={{ color: '#44aa00' }}
            aria-label="edit comment"
            onClick={() => setEditing(true)}
          >
            <EditIcon />
          </IconButton>
        </Grid>
      )}
      {editing && (
        <Grid container direction="row">
          <div
            style={{ minWidth: width || 400 }}
          >
            <TextField
              required
              label="comments"
              multiline
              rowsMax={4}
              defaultValue={content}
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) => {
                setContent(e.target.value);
              }}
            />
          </div>
          <IconButton
            style={{ color: '#44aa00' }}
            aria-label="save comment"
            onClick={() => {
              setEditing(false);
              if (onSave) {
                onSave(content);
              }
            }}
          >
            <DoneIcon />
          </IconButton>
          <IconButton
            style={{ color: '#ff2a2a' }}
            aria-label="reject comment"
            onClick={() => {
              setContent(text);
              setEditing(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      )}
    </div>
  );
}
