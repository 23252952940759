import React from 'react';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';

// import * as Utils from '../utils';

function TeamAvatars(props) {
  const { teams, metaData } = props;
  const teamList = metaData && metaData.length > 0 ? metaData[0].teamList : [];

  return (
    <Grid container direction="row">
      {teams && teams.map((x) => {
        let t = teamList ? teamList.find((y) => y.id === x) : { name: 'K', color: 'white' };
        t = t ? t : { name: 'K', color: 'white' };
        return (
          <div
            size="small"
            key={x}
            style={{
              backgroundColor: t.color,
              marginHorizontal: 1,
              paddingLeft: 4,
              paddingRight: 4,
              borderRadius: 3,
              fontSize: 12,
            }}
          >
            {t.name.substring(0, 10)}
          </div>
        );
      })}
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  metaData: state.firestore.metaData,
});

export default connect(mapStateToProps)(TeamAvatars);
