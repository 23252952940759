// React core.
import React from 'react';
import { Redirect } from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar';

/**
 * The Splash Page containing the login UI.
 */
export default class Login extends React.Component {
  state = {
    isSignedIn: window.isSignedIn,
    message: '',
    displayName: ''
  };

  /**
   * @inheritDoc
   */
  componentDidMount() {
    const id = setInterval(() => {
      if (window.isSignedIn) {
        const email = window.email ? window.email.toLowerCase() : '';
        window.firestore.collection('roleMap').doc(email).get()
          .then((ret) => {
            // console.log('Role: ', global.role, ret.exists);
            if (ret.exists && ret.data().isRegular) {
              global.role = ret.data();
              this.setState({isSignedIn: true, displayName: window.displayName});
              clearInterval(id);
            } else {
              this.setState({message: window.email + ' you do not have permission to log in. Please contact the administrator.'});
            }
          }).catch(err => {
            this.setState({message: err.message});
          });
      } else {
        this.setState({isSignedIn: false});
        // window.ui.start('#firebaseui-auth-container', window.uiConfig);
      }
    }, 1000);
  }

  /**
   * @inheritDoc
   */
  componentWillUnmount() {
  }


  /**
   * @inheritDoc
   */
  render() {
    const from = {pathname: '/dashboard'};
      return (
        <div>
          {this.state.isSignedIn && <Redirect to={from} />}
          <div>
            <Toolbar style={{backgroundColor: '#2ca05a'}}>
              <h2 style={{color: 'white'}}>
                Myndrix HR Dashboard
              </h2>
            </Toolbar>
          </div>
          <div id="firebaseui-auth-container" style={{marginTop: 80}}></div>
          {this.state.isSignedIn === undefined &&
           <div style={{marginTop: 80}}>
             Checking credentials......
           </div>
          }
          <div>
            <h4>{this.state.message}</h4>
          </div>
        </div>
      );

  }
}
