/* eslint-disable react/prop-types, no-use-before-define */
import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';

import CloseIcon from '@material-ui/icons/Close';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import * as Utils from '../utils';
import EditableText from './EditableText';
import MyndScheduler from './MyndScheduler';
import MyndForm from './MyndForm';
import getProfileStates from '../profileStates';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function HistoryWindow(props) {
  const { disabled, row, users } = props;
  const onClose = props.onClose ? props.onClose : () => {};
  const [open, setOpen] = React.useState(false);
  const [history, setHistory] = React.useState([]);

  let profileId = null;
  let historyRef = null;
  let unsubscribe = null;

  useEffect(() => {
    setOpen(!disabled);
    if (row && !unsubscribe) {
      profileId = row.origProfileId ? row.origProfileId : row.profileId;
      historyRef = window.firestore
        .collection('data')
        .doc(global.role.orgId)
        .collection('history')
        .doc(profileId);
      unsubscribe = historyRef.onSnapshot(onHistoryUpdate);
    }
  }, [disabled, users]);

  if (!row) return null;

  const data = Utils.deepCopy(row);
  data.experience = Utils.months2words(data.experience);
  data.ctc = Utils.lakhs2words(data.ctc);
  data.notice = Utils.days2words(data.notice);
  const title = data.name;

  const handleClose = () => {
    // console.log(data);
    // setOpen(false);
    if (unsubscribe) {
      unsubscribe();
    }
    unsubscribe = null;
    setHistory([]);
    onClose();
  };

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

  const DialogTitle = withStyles(styles)((props) => {
    const {
      children,
      classes,
      onClose,
      ...other
    } = props;
    return (
      <MuiDialogTitle
        disableTypography
        className={classes.root}
        {...other}
        style={{ backgroundColor: '#2ca05a', color: 'white' }}
      >
        <Typography variant="h5">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const onHistoryUpdate = (docSnap) => {
    const h = docSnap.data();
    if (!h || h.length === 0) return;
    let msgs = [];
    Object.keys(h).forEach((k) => {
      msgs.push(h[k]);
    });
    msgs = msgs.sort((a, b) => a.time < b.time);
    setHistory(msgs);
  };

  const appendHistory = (pId, message, prevState, state) => {
    const time = Utils.timestamp();
    window
      .firestore
      .collection('data')
      .doc(global.role.orgId)
      .collection('history')
      .doc(pId)
      .set({
        [time]: {
          time,
          authorId: global.role.appUid,
          authorOrg: global.role.orgId,
          message,
          prevState,
          state,
        },
      }, { merge: true });
  };

  const updateProfile = (rec, pId) => window.firestore
    .collection('data')
    .doc(global.role.orgId)
    .collection('profiles')
    .doc(pId)
    .set(rec, { merge: true });

  const renderForm = () => {
    console.log(global.role);
    const spec = {
      onSubmit: (val) => submitChange(val),
      buttonColor: '#5fd38d',
      items: [
        {
          // 0
          title: 'Phone',
          type: 'text',
          field: 'phone',
          mandatory: true,
          editable: true,
        },
        {
          // 1
          title: 'Email',
          type: 'text',
          field: 'email',
          mandatory: true,
        },
        {
          // 2
          title: 'Recruiter',
          type: 'text',
          field: 'userName',
          mandatory: true,
        },
        {
          // 3
          title: 'CTC (LPA)',
          type: 'number',
          field: 'ctc',
          editable: true,
        },
        {
          // 4
          title: 'Expected CTC (LPA)',
          type: 'number',
          field: 'ectc',
          editable: true,
        },
        {
          // 5
          title: 'Notice Period (days)',
          type: 'number',
          field: 'notice',
          editable: true,
        },
        {
          // 6
          title: 'Profile ID',
          type: 'text',
          field: 'profileId',
          editable: false,
        },
      ],
    };
    if (global.role.email === 'ajay@myndrix.com') {
      const ps = getProfileStates();
      spec.items.push({
        title: 'Stage',
        type: 'buttonGroup',
        field: 'state',
        params: ps.map((x) => x.title),
        editable: true,
      });
    }
    const submitChange = (val) => {
      const rec = { modifiedDate: Utils.timestamp() };
      // Copy out only what we need
      spec.items.forEach((x) => {
        if (val[x.field]) {
          rec[x.field] = val[x.field];
        }
      });
      if (Object.keys(rec).length > 0) {
        updateProfile(rec, row.profileId);
        // eslint-disable-next-line
        const message = `Edited: ${Object.keys(rec).map((k) => ' ' + k + ': ' + rec[k])}`;
        appendHistory(row.profileId, message, row.state, row.state);
        // console.log(rec, message);
      }
      handleClose();
      return null;
    };
    return <MyndForm spec={spec} initValues={{ ...row }} />;
  };

  const renderMessage = (m) => {
    const author = `${users[m.authorId].name} at ${new Date(m.time)}`;
    return (
      <div
        style={{
          backgroundColor: '#e6e6e6',
          padding: 5,
          borderRadius: 3,
          margin: 3,
        }}
      >
        <Typography variant="body1">{m.message}</Typography>
        <Typography variant="caption">{author}</Typography>
      </div>
    );
  };

  const renderHistory = () => (
    <div>
      <Typography variant="h6">History of Changes</Typography>
      {history.map((x) => (
        <div key={x.time}>
          {renderMessage(x)}
        </div>
      ))}
    </div>
  );

  const renderSchedule = () => {
    profileId = row.origProfileId ? row.origProfileId : row.profileId;
    return (
      <div>
        <Grid container direction="row">
          <Typography variant="h6">Schedule</Typography>
          <Tooltip title="Schedule reminders or set milestones">
            <HelpOutlineIcon style={{ width: 20, marginLeft: 10, color: '#808080' }} />
          </Tooltip>
        </Grid>
        <MyndScheduler profileId={profileId} />
      </div>
    );
  };

  const renderComment = () => (
    <div>
      <Typography variant="h6">Comments</Typography>
      <EditableText
        text={data.comment}
        onSave={(text) => {
          const profileRef = window.firestore
            .collection('data')
            .doc(global.role.orgId)
            .collection('profiles').doc(data.profileId);
          profileRef.set({ comment: text, modifiedDate: Utils.timestamp() }, { merge: true });
          appendHistory(data.profileId, text, row.state, row.state);
        }}
      />
    </div>
  );

  return (
    <div>
      <Dialog
        open={open}
        maxWidth="md"
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          style={{ cursor: 'move' }}
          id="draggable-dialog-title"
          onClose={handleClose}
        >
          {title}
        </DialogTitle>
        <DialogContent dividers>
          {renderForm()}
        </DialogContent>
        <DialogContent dividers>
          {renderComment()}
        </DialogContent>
        <DialogContent dividers>
          {renderSchedule()}
        </DialogContent>
        <DialogContent dividers>
          {renderHistory()}
        </DialogContent>
        {/*
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Subscribe

          </Button>
          </DialogActions>
         */}
      </Dialog>
    </div>
  );
}
