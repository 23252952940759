import React from 'react';
import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import { useSnackbar } from 'notistack';
import Container from '@material-ui/core/Container';

import * as Utils from '../utils';

function Users(props) {
  const { enqueueSnackbar } = useSnackbar();

  const addCompany = (row) => {
    if (global.role && !global.role.isManager) {
      enqueueSnackbar("Sorry, you don't have permission to add a company..", { variant: 'error' });
      return new Promise((resolve) => {
        setTimeout(() => { resolve(); }, 500);
      });
    }
    if (!row.name || !row.primaryName || !row.primaryEmail) {
      enqueueSnackbar('Company name, contact and email are required fields..', { variant: 'error' });
      return new Promise((resolve) => {
        setTimeout(() => { resolve(); }, 500);
      });
    }
    const modified = row.companyId;
    row.companyId = modified ? row.companyId : Utils.UUID();
    return window
      .firestore
      .collection('data')
      .doc(global.role.orgId)
      .collection('companies')
      .doc(row.companyId)
      .set(row)
      .then(() => {
        const modStr = modified ? ' modified!' : ' added!';
        enqueueSnackbar(`Company ${row.name} ${modStr}`, { variant: 'success' });
      })
      .catch((err) => {
        enqueueSnackbar(err, { variant: 'error' });
      });
  };

  const delCompany = (row) => window
    .firestore
    .collection('data')
    .doc(global.role.orgId)
    .collection('companies')
    .doc(row.companyId)
    .delete()
    .then(() => {
      enqueueSnackbar(`Company ${row.name} deleted!`, { variant: 'success' });
    })
    .catch((err) => {
      enqueueSnackbar(`Error while deleting ${err}`, { variant: 'error' });
    });

  const renderTable = () => {
    const cos = props.companies;
    const cols = [
      { title: 'Name', field: 'name' },
      { title: 'Primary Contact', field: 'primaryName' },
      { title: 'Email', field: 'primaryEmail' },
      { title: 'Web', field: 'webSite' },
    ];

    const active = cos ? cos.filter(x => x.isActive) : [];
    const inActive = cos ? cos.filter(x => !x.isActive) : [];
    const data = active.concat(inActive);

    const options = {
      pageSize: 5,
      headerStyle: {
        backgroundColor: '#afe9c6e0',
      },
    };

    const editable = {
      isEditable: () => {
        if (global.role && global.role.isManager) {
          return true;
        }
        return false;
      },
      isDeletable: () => {
        if (global.role && global.role.isManager) {
          return true;
        }
        return false;
      },
      onRowAdd: (newData) => addCompany(newData),
      onRowUpdate: (newData) => addCompany(newData),
      // onRowDelete: (oldData) => delCompany(oldData),
    };

    return (
      <MaterialTable
        title="Companies"
        columns={cols}
        data={data}
        options={options}
        isLoading={false}
        editable={editable}
      />
    );
  };

  return (
    <Container
      maxWidth="lg"
      style={{ marginTop: 20 }}
    >
      {renderTable()}
    </Container>
  );
}

const mapStateToProps = (state) => ({
  companies: state.firestore.companies,
  roles: state.firestore.roles,
});

export default connect(mapStateToProps)(Users);
