import React from 'react';
import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import { useSnackbar } from 'notistack';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import CloseIcon from '@material-ui/icons/Close';
import DownloadIcon from '@material-ui/icons/GetApp';

import MyndStepper from './MyndStepper';
import * as Utils from '../utils';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const {
    children,
    classes,
    onClose,
    ...other
  } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
      style={{ backgroundColor: '#4d4d4d', color: 'white' }}
    >
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function JDs(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { jds, companies } = props;
  const [dialog, setDialog] = React.useState({ show: false, row: null });

  const renderStepper = () => {
    const spec = {
      title: 'JD',
      buttonColor: '#5fd38dff',
      onSubmit: (val) => {
        const rec = {
          name: val.name,
          companyId: companies[val.companyIndex].companyId,
          team: val.team,
          city: val.city,
          ctc: val.ctc,
          skills: val.skills,
          link: val.link,
          description: val.description,
          id: Utils.UUID(),
        };
        window.firestore
          .collection('data')
          .doc(global.role.orgId)
          .collection('jds')
          .doc(rec.id)
          .set(rec, { merge: true })
          .then(() => {
            enqueueSnackbar(`JD ${rec.name} added!`, { variant: 'success' });
          })
          .catch((err) => {
            enqueueSnackbar(err, { variant: 'error' });
          });
      },
      steps: [
        {
          // 0
          title: 'JD Name',
          type: 'text',
          field: 'name',
          nxtAction: (val) => {
            if (!val.name) return { error: true, message: 'JD Name is required' };
            return { error: false };
          },
        },
        {
          // 1
          title: 'Company',
          type: 'autocomplete',
          params: companies.map((x) => ({ name: x.name })),
          field: 'company',
          nxtAction: (val) => {
            if (!val.company) return { error: true, message: 'Company is a required field' };
            return { error: false };
          },
        },
        {
          // 2
          title: 'Team/Division',
          type: 'text',
          field: 'team',
        },
        {
          // 3
          title: 'City',
          type: 'text',
          field: 'city',
        },
        {
          // 4
          title: 'CTC',
          type: 'text',
          field: 'ctc',
        },
        {
          // 5
          title: 'Skills Required (comma separated)',
          type: 'text',
          field: 'skills',
          nxtAction: (val) => {
            if (!val.skills) return { error: true, message: 'Skills is a required field' };
            return { error: false };
          },
        },
        {
          // 6
          title: 'Description',
          type: 'multilineText',
          field: 'description',
          nxtAction: (val) => {
            if (!val.description) return { error: true, message: 'Description is a required field' };
            return { error: false };
          },
        },
        {
          // 7
          title: 'Link to JD (Optional)',
          type: 'text',
          field: 'link',
        },
      ],
    };

    return <MyndStepper spec={spec} />;
  };

  const renderDialog = () => {
    if (!dialog.show) {
      return null;
    }
    const handleClose = () => setDialog({ show: false, row: null });
    const title = `${dialog.row.name}, ${dialog.row.company}`;
    const renderDescription = () => (
      <Typography variant="body1">
        {dialog.row.description}
      </Typography>
    );

    return (
      <Dialog
        open={dialog.show}
        maxWidth="md"
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          style={{ cursor: 'move' }}
          id="draggable-dialog-title"
          onClose={handleClose}
        >
          {title}
        </DialogTitle>
        {/*
        <DialogContent dividers>
          {renderDetails()}
        </DialogContent>
        */}
        <DialogContent dividers>
          {renderDescription()}
        </DialogContent>
      </Dialog>
    );
  };

  const renderTable = () => {
    if (!jds) {
      return null;
    }
    const data = jds
      .map((x) => {
        const entry = { ...x };
        const company = companies.find((y) => y.companyId === x.companyId);
        entry.company = company.name || x.companyId.substring(0, 8);
        return entry;
      })
      .sort((a, b) => (a.company < b.company ? -1 : 1));
    const cols = [
      { title: 'Name', field: 'name' },
      { title: 'Team', field: 'team' },
      {
        title: 'Company',
        field: 'company',
      },
      { title: 'city', field: 'city' },
      { title: 'CTC', field: 'ctc' },
      { title: 'Skills', field: 'skills' },
    ];

    const options = {
      pageSizeOptions: [20, 40, 60],
      pageSize: 20,
      headerStyle: {
        backgroundColor: '#afe9c6e0',
      },
    };

    const onRowClick = (e, rowData) => {
      setDialog({ show: true, row: rowData });
    };

    return (
      <MaterialTable
        title="Job Descriptions"
        columns={cols}
        data={data}
        options={options}
        isLoading={false}
        onRowClick={onRowClick}
      />
    );
  };

  const renderDownload = () => {
    return (
      <IconButton
        aria-label="download"
        onClick={() => {
          window.firestore
            .collection('data')
            .doc(global.role.orgId)
            .collection('history')
            .get()
            .then((qSnap) => {
              const d = [];
              qSnap.forEach((x) => {
                const profileId = x.id;
                const { orgId } = global.role;
                const data = x.data();
                Object.keys(data).forEach((k) => {
                  const e = data[k];
                  d.push({
                    orgId,
                    profileId,
                    authorId: e.authorId,
                    authorOrg: e.authorOrg,
                    message: e.message,
                    state: e.state,
                    time: e.time,
                  });
                });
              });
              // console.log(d);
              console.log(d.length);
              const f = JSON.stringify(d);
              const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(f)}`;
              const downloadAnchorNode = document.createElement('a');
              downloadAnchorNode.setAttribute('href', dataStr);
              downloadAnchorNode.setAttribute('download', 'history.json');
              document.body.appendChild(downloadAnchorNode); // required for firefox
              downloadAnchorNode.click();
              downloadAnchorNode.remove();
            });
        }}
      >
        <DownloadIcon />
      </IconButton>
    );
  };

  return (
    <Container
      maxWidth="lg"
      style={{ marginTop: 20 }}
    >
      {renderStepper()}
      {/* renderDownload() */}
      {renderTable()}
      {renderDialog()}
    </Container>
  );
}

const mapStateToProps = (state) => ({
  jds: state.firestore.jds,
  companies: state.firestore.companies,
  roles: state.firestore.roles,
});

export default connect(mapStateToProps)(JDs);
