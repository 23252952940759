/* eslint-disable react/prop-types, no-param-reassign, no-shadow */
import React from 'react';

import { useSnackbar } from 'notistack';
import { FilePicker } from 'react-file-picker';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { isValidPhoneNumber } from 'react-phone-number-input';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import CloseIcon from '@material-ui/icons/Close';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DoneIcon from '@material-ui/icons/Done';
import ForwardIcon from '@material-ui/icons/ArrowForward';
import AddIcon from '@material-ui/icons/Add';

import EmailInput from './EmailInput';
import NameInput from './NameInput';
import DualInput from './DualInput';

import * as Utils from '../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formControl: {
    minWidth: 130,
    marginTop: 10,
  },
  fixedHeight: {
    height: 240,
  },
}));

const valueArray = ['', '', '']; // Array of 3 empties
const roleArray = ['', '', '']; // Array of 3 empties

export default function CandidateDetails(props) {
  const {
    generateComments,
    appendHistory,
    companies,
    jds,
  } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { orgId } = global.role;
  const colRef = window.firestore.collection('data').doc(orgId).collection('profiles');
  const storageRef = window.storage.ref();

  const [inlineEdit, setInlineEdit] = React.useState(false);
  const [entrySet, setEntrySet] = React.useState(0);
  const [phone, setPhone] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [name, setName] = React.useState('');
  const [perror, setPError] = React.useState(true);
  const [eerror, setEError] = React.useState(true);
  const [nerror, setNError] = React.useState(true);
  const [notice, setNotice] = React.useState(0);
  const [experience, setExperience] = React.useState(0);
  const [ctc, setCtc] = React.useState(0);
  const [qualification, setQualification] = React.useState('');
  const [yearPassing, setYearPassing] = React.useState(new Date());
  const [selectedValue, setSelectedValue] = React.useState(valueArray);
  const [selectedRole, setSelectedRole] = React.useState(roleArray);
  const [file, setFile] = React.useState(null);

  const selectedCompanyRef = React.useRef(null);

  const resetFields = () => {
    setFile(null);
    setPhone('');
    setEmail('');
    setName('');
    setNotice(0);
    setExperience(0);
    setCtc(0);
    setQualification('');
    setYearPassing(new Date());
  };

  const renderInput = () => (
    <Grid item xs={12}>
      {!inlineEdit && (
        <Grid container direction="row" style={{ minHeight: 95 }}>
          <Button
            style={{ marginLeft: 13, backgroundColor: '#5fd38d', height: 40 }}
            color="primary"
            variant="contained"
            aria-label="add"
            onClick={() => { resetFields(); setInlineEdit(true); }}
          >
            <AddIcon />
            Profile
          </Button>
        </Grid>
      )}
      {inlineEdit && (
        <Paper className={classes.paper}>
          <h4 style={{ color: '#1a1a1a' }}>{name || 'New Candidate'}</h4>
          {entrySet === 0 && (
            <Grid container direction="row" justify="center">
              <FilePicker
                onChange={(f) => {
                  setFile(f);
                  setEntrySet(1);
                }}
                onError={(err) => {
                  enqueueSnackbar(err, { variant: 'error' });
                }}
              >
                <Button
                  aria-label="next"
                  color="primary"
                  variant="outlined"
                  startIcon={<CloudUploadIcon />}
                  style={{ marginLeft: 20 }}
                >
                  Upload Resume
                </Button>
              </FilePicker>
              <Button
                aria-label="next"
                color="primary"
                variant="outlined"
                startIcon={<ForwardIcon />}
                style={{ marginLeft: 20 }}
                onClick={() => { setEntrySet(1); setFile(null); }}
              >
                Skip Upload
              </Button>
            </Grid>
          )}
          {entrySet === 1 && (
            <Grid container direction="row">
              <Grid md={4} lg={3} item>
                <PhoneInputLocal
                  retVal={(ret) => {
                    setPhone(ret.value);
                    setPError(ret.error);
                  }}
                />
              </Grid>
              <Grid md={4} lg={3} item>
                <EmailInput
                  retVal={(ret) => {
                    setEmail(ret.value);
                    setEError(ret.error);
                  }}
                />
              </Grid>
              <Grid md={4} lg={3} item>
                <NameInput
                  retVal={(ret) => {
                    setName(ret.value);
                    setNError(ret.error);
                  }}
                />
              </Grid>
              <Grid md={4} lg={3} item>
                <Button
                  aria-label="close"
                  color="primary"
                  variant="outlined"
                  size="small"
                  startIcon={<CloseIcon />}
                  onClick={() => {
                    setInlineEdit(false);
                    setEntrySet(0);
                    resetFields();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  aria-label="next"
                  color="primary"
                  variant="outlined"
                  disabled={perror || eerror || nerror}
                  startIcon={<ForwardIcon />}
                  size="small"
                  style={{ marginLeft: 20 }}
                  onClick={() => setEntrySet(4)}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          )}
          {entrySet === 2 && (
            <Grid container direction="row">
              <Grid md={4} lg={3} item>
                <DualInput
                  monthDays
                  label="Notice period"
                  onChange={(days) => setNotice(Number(days))}
                />
              </Grid>
              <Grid md={4} lg={3} item>
                <DualInput
                  yearMonths
                  label="Experience"
                  onChange={(months) => setExperience(Number(months))}
                />
              </Grid>
              <Grid md={4} lg={3} item>
                <DualInput
                  lakhThousands
                  label="Current CTC"
                  onChange={(ths) => setCtc(Number(ths))}
                />
              </Grid>
              <Grid md={4} lg={3} item>
                <Button
                  aria-label="close"
                  color="primary"
                  variant="outlined"
                  size="small"
                  startIcon={<CloseIcon />}
                  onClick={() => {
                    setInlineEdit(false);
                    setEntrySet(0);
                    resetFields();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  aria-label="next"
                  color="primary"
                  variant="outlined"
                  disabled={false} // &&  perror || eerror || nerror}
                  startIcon={<ForwardIcon />}
                  size="small"
                  style={{ marginLeft: 20 }}
                  onClick={() => setEntrySet(3)}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          )}
          {entrySet === 3 && (
            <Grid container direction="row">
              <Grid md={4} lg={3} item style={{ marginTop: 15 }}>
                <NameInput
                  label="qualification"
                  helperText="Candidate's qualification"
                  required={false}
                  retVal={(ret) => {
                    setQualification(ret.value);
                  }}
                />
              </Grid>
              <Grid md={4} lg={3} item>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    disableFuture
                    variant="inline"
                    margin="normal"
                    views={['year']}
                    label="Year of passing"
                    value={yearPassing}
                    onChange={(date) => setYearPassing(date)}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid md={4} lg={3} item>
                <Button
                  aria-label="close"
                  color="primary"
                  variant="outlined"
                  size="small"
                  startIcon={<CloseIcon />}
                  onClick={() => {
                    setInlineEdit(false);
                    setEntrySet(0);
                    resetFields();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  aria-label="next"
                  color="primary"
                  variant="outlined"
                  disabled={false} // &&  perror || eerror || nerror}
                  startIcon={<ForwardIcon />}
                  size="small"
                  style={{ marginLeft: 20 }}
                  onClick={() => setEntrySet(4)}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          )}
          {entrySet === 4 && (
            <Grid container direction="row">
              <Grid md={6} direction="row">
                <Grid container direction="row">
                  <Grid item md={6} style={{ minWidth: 200 }}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel id="demo-simple-select-label">Company</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedValue[0]}
                        error={selectedRole[0] !== '' && selectedValue[0] === ''}
                        onChange={(e) => {
                          const s = [...selectedValue];
                          s[0] = e.target.value;
                          setSelectedValue(s);
                          selectedCompanyRef.current = companies.find((c) => c.name === s[0]);
                        }}
                      >
                        {companies.sort().map((co) => (
                          <MenuItem value={co.name} key={co.name}>{co.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={6} style={{ minWidth: 200 }}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel id="demo-simple-select-label">Role</InputLabel>
                      <Select
                        labelId="demo-simple-select-label1"
                        id="demo-simple-select1"
                        value={selectedRole[0]}
                        error={selectedRole[0] !== '' && selectedValue[0] === ''}
                        onChange={(e) => {
                          const s = [...selectedRole];
                          s[0] = e.target.value;
                          setSelectedRole(s);
                        }}
                      >
                        {jds
                          .filter((j) => {
                            if (selectedCompanyRef.current) {
                              return j.companyId === selectedCompanyRef.current.companyId;
                            }
                            return j;
                          })
                          .sort()
                          .map((co) => (
                            <MenuItem value={co.name} key={co.name}>{co.name}</MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid md={4} lg={3} item>
                <Button
                  aria-label="close"
                  color="primary"
                  variant="outlined"
                  size="small"
                  startIcon={<CloseIcon />}
                  onClick={() => {
                    setEntrySet(0);
                    setInlineEdit(false);
                    resetFields();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  aria-label="next"
                  color="primary"
                  variant="outlined"
                  disabled={false}
                  startIcon={<DoneIcon />}
                  size="small"
                  style={{ marginLeft: 20 }}
                  onClick={() => {
                    setEntrySet(0);
                    setInlineEdit(false);
                    const resumes = [];
                    if (file) {
                      const objName = `resumes/${Utils.UUID()}`;
                      const fileRef = storageRef.child(objName);
                      resumes.push({ object: objName, name: file.name, URL: '' });
                      try {
                        const task = fileRef.put(file);
                        task.on(
                          window.firebase.storage.TaskEvent.STATE_CHANGED,
                          (snap) => {}, // State change
                          (err) => { // Error
                            enqueueSnackbar(
                              `Upload failed with code ${err.code}`,
                              { variant: 'error' },
                            );
                            setFile(null);
                          },
                          () => { // Success
                            enqueueSnackbar(
                              'Uploaded resume!',
                              { variant: 'success' },
                            );
                            setFile(null);
                          },
                        );
                      } catch (error) {
                        enqueueSnackbar(
                          `Upload failed. ${error.message}`,
                          { variant: 'error' },
                        );
                        setFile(null);
                        return;
                      }
                    }
                    const profileId = Utils.UUID();
                    const jd = jds.find((x) => x.name === selectedRole[0]);
                    const rec = {
                      profileId,
                      name,
                      phone,
                      email,
                      notice,
                      experience,
                      ctc,
                      createUid: global.role.appUid,
                      createdDate: Utils.timestamp(),
                      modifiedDate: Utils.timestamp(),
                      ownUid: global.role.appUid,
                      state: 'inbox',
                      resumes,
                      qualification,
                      company: selectedValue[0],
                      companyId: selectedCompanyRef.current.companyId,
                      role: selectedRole[0],
                      roleId: jd ? jd.id : 0,
                      yearPassing: Math.floor(yearPassing),
                    };
                    const com = generateComments(rec, rec);
                    rec.comment = com.comment;
                    // console.log(rec);
                    colRef.doc(profileId).set(rec)
                      .then(() => {
                        enqueueSnackbar(
                          'Profile added!',
                          { variant: 'success' },
                        );
                      })
                      .catch((err) => {
                        enqueueSnackbar(
                          `Error adding profile ${err.message}`,
                          { variant: 'error' },
                        );
                      });
                    // Add history entry
                    appendHistory(rec, com);
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          )}
        </Paper>
      )}
    </Grid>
  );

  return renderInput();
}

function PhoneInputLocal(props) {
  const [error, setError] = React.useState(true);
  return (
    <TextField
      required
      label="phone number"
      type="phone"
      defaultValue="+91"
      variant="outlined"
      size="small"
      error={error}
      helperText="Candidate's phone"
      onChange={(e) => {
        const valid = e.target.value.length > 15 ? false : isValidPhoneNumber(e.target.value);
        setError(!valid);
        if (props.retVal) {
          props.retVal({ error: !valid, value: e.target.value });
        }
      }}
    />
  );
}
