/* eslint-disable no-console, react/prop-types */
import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import getProfileStates from '../profileStates';

import Pipeline from './Pipeline';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formControl: {
    minWidth: 130,
    marginTop: 10,
  },
  fixedHeight: {
    height: 240,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function Analytics() {
  const classes = useStyles();
  const profileStates = getProfileStates();
  const fn = window.functions.httpsCallable('query');
  const [funnel, setFunnel] = useState([]);
  const [periodicity] = useState('monthly');
  const [backdrop, setBackdrop] = useState(true);

  const buildFunnelQuery = (interval) => {
    const states = profileStates.map((s) => s.title).filter((s) => s !== 'all');
    const DB = 'rivera-b8e0f.Profiles.History';
    let query = 'SELECT ';
    states.forEach((s) => {
      query += `COUNT(CASE WHEN state = '${s}' THEN 1 ELSE NULL END) AS ${s}, `;
    });
    query += `FROM (SELECT DISTINCT profileId, state FROM ${DB} WHERE orgId = '${global.role.orgId}' `;
    query += `AND time > ${interval.start} AND time < ${interval.end})`;
    return query;
  };

  const callFunnelQuery = () => {
    setBackdrop(true);
    let periods = [];
    if (periodicity === 'monthly') {
      periods = [5, 4, 3, 2, 1, 0].map((p) => {
        const today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMonth(today.getMonth() - p);
        return {
          start: Math.floor(today.setDate(1)),
          end: Math.floor(today.setMonth(today.getMonth() + 1)),
        };
      });
    }
    const promises = [];
    periods.forEach((p) => {
      const query = buildFunnelQuery(p);
      // console.log(query);
      const pr = fn({ query })
        .then((res) => {
          const r = { ...res.data[0][0] };
          r.start = p.start;
          r.end = p.end;
          return r;
        });
      promises.push(pr);
    });
    Promise
      .all(promises)
      .then((res) => {
        setFunnel(res);
        setBackdrop(false);
      });
  };

  const callQueries = () => {
    callFunnelQuery();
  };

  useEffect(() => {
    callQueries();
  }, []);

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Backdrop className={classes.backdrop} open={backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container direction="column" spacing={3}>
        <Pipeline data={funnel} />
      </Grid>
    </Container>
  );
}
