/* eslint-disable no-console, react/no-array-index-key */
import React, { useState, useEffect, useRef } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
// import { createHash } from 'crypto-browserify';

import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import AddIcon from '@material-ui/icons/Add';

import EditableText from './EditableText';
import * as Utils from '../utils';

export default function MyndScheduler(props) {
  const { profileId } = props;
  const [schedules, setSchedules] = useState([]);
  const scheduleRef = useRef(null);
  const schedulesRef = useRef([]);
  const memoRef = useRef(null);

  useEffect(() => {
    scheduleRef.current = window
      .firestore
      .collection('data')
      .doc(global.role.orgId)
      .collection('schedule')
      .doc(profileId);
    scheduleRef
      .current
      .get()
      .then((ret) => {
        const data = ret.exists ? ret.data() : schedules;
        setSchedules(data);
        memoRef.current = Utils.sha256(JSON.stringify(data));
        // console.log('begin', JSON.stringify(data), memoRef.current);
      })
      .catch((err) => {
        console.log('Error!', err);
      });

    return () => {
      const digest = Utils.sha256(JSON.stringify(schedulesRef.current));
      // console.log('final', JSON.stringify(schedulesRef.current), digest);
      // console.log(memoRef.current, digest);
      if (digest !== memoRef.current) {
        console.log('Saving ');
      }
    };
  }, [profileId]);

  const renderList = () => schedules.map((x, idx) => (
    <Grid container direction="row" key={`${x.date}-${idx}`}>
      <Grid item sm={4}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DateTimePicker
            value={x.date}
            onChange={(newDate) => {
              const s = [...schedules];
              s[idx].date = newDate;
              setSchedules(s);
              schedulesRef.current = s;
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item sm={5} style={{ marginLeft: 20 }}>
        <EditableText
          text={x.description}
          width={200}
          onSave={(text) => {
            const s = [...schedules];
            s[idx].description = text;
            setSchedules(s);
            schedulesRef.current = s;
          }}
        />
      </Grid>
      <Grid item sm={2}>
        <Grid container direction="column" alignItems="flex-end" justify="center">
          <Button
            color="secondary"
            aria-label="remove"
            onClick={() => {
              const s = [...schedules];
              s.splice(idx, 1);
              setSchedules(s);
              schedulesRef.current = s;
            }}
          >
            remove
          </Button>
        </Grid>
      </Grid>
    </Grid>
  ));

  const renderAdd = () => (
    <Grid container direction="row">
      <IconButton
        aria-label="add"
        onClick={() => {
          const s = [...schedules];
          s.splice(0, 0, {
            date: new Date(),
            description: 'Description',
          });
          setSchedules(s);
          schedulesRef.current = s;
        }}
      >
        <AddIcon />
      </IconButton>
    </Grid>
  );

  return (
    <Grid container>
      {renderAdd()}
      {renderList()}
    </Grid>
  );
}
