/* eslint-disable no-console, react/prop-types */
import React from 'react';

import Grid from '@material-ui/core/Grid';

import PipelineStage from './PipelineStage';
import AllStages from './AllStages';
import Closures from './Closures';

const pipelineStages = [
  {
    title: 'Prospecting',
    states: ['inbox', 'shortlisted', 'qualified'],
  },
  {
    title: 'Company Interaction',
    states: ['qualified', 'scheduled', 'interviewed'],
  },
  {
    title: 'Selection',
    states: ['interviewed', 'selected', 'offered', 'joined'],
  },
];

export default function Pipeline(props) {
  const { data } = props;
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item sm={12}>
        <Closures data={data} />
      </Grid>
      <Grid item sm={12}>
        <AllStages data={data} />
      </Grid>
      {pipelineStages.map((x, idx) => (
        <Grid item sm={12} md={4} key={x.title}>
          {/* renderStage(x, idx) */}
          <PipelineStage data={data} stage={x} index={idx} />
        </Grid>
      ))}
    </Grid>
  );
}
