import React from 'react';

import AssignmentInd from '@material-ui/icons/AssignmentInd';
import BusinessIcon from '@material-ui/icons/Business';
import PeopleIcon from '@material-ui/icons/People';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BarChartIcon from '@material-ui/icons/BarChart';

import Profiles from './components/Profiles';
import Users from './components/Users';
import Companies from './components/Companies';
import JDs from './components/JDs';
import AnalyticsLayout from './layouts/AnalyticsLayout';

const menuItems = [
  {
    title: 'Analytics',
    icon: <BarChartIcon />,
    component: AnalyticsLayout,
    path: '/dashboard/analytics',
  },
  {
    title: 'Profiles',
    icon: <AssignmentInd />,
    component: Profiles,
    path: '/dashboard/profiles',
  },
  {
    title: 'Users',
    icon: <PeopleIcon />,
    component: Users,
    path: '/dashboard/users',
  },
  {
    title: 'Companies',
    icon: <BusinessIcon />,
    component: Companies,
    path: '/dashboard/companies',
  },
  {
    title: 'JDs',
    icon: <AssignmentIcon />,
    component: JDs,
    path: '/dashboard/jds',
  },
];

export default menuItems;
