import React from 'react';
import { useSnackbar } from 'notistack';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Ratings from 'react-ratings-declarative';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';

import * as Utils from 'utils';
import EmailInput from 'components/EmailInput';

export default function MyndStepper(props) {
  const { spec } = props;

  const firstStep = spec.skipStart ? 0 : -1;
  const [step, setStep] = React.useState(firstStep);
  const [stack, setStack] = React.useState([firstStep]);
  const [val, setVal] = React.useState({});

  const { enqueueSnackbar } = useSnackbar();

  const styles = {
    container: {
      marginBottom: 10,
    },
    paper: {
      marginBottom: 5,
      padding: 10,
      minHeight: 60,
    },
    direction: {
      marginTop: 5,
      marginLeft: 20,
    },
    body: {
      marginBottom: 5,
    },
  };

  const renderAutocomplete = () => {
    const thisStep = spec.steps[step];
    const selections = (typeof thisStep.params === "function") ? thisStep.params(val) : [...thisStep.params];
    selections.map((x, idx) => {x.index = idx; return x;});
    const prevIndex = val[thisStep.field + 'Index'];
    const value = prevIndex !== undefined ? selections[prevIndex] : '';  // Rewind logic
    return (
      <Grid item md={12} lg={6}>
      <Autocomplete
        id="select-assignee"
        value={value}
        autoFocus={true}
        autoSelect
        options={selections}
        getOptionLabel={option => option.name}
        onChange={(e, v) => {
          if(!v) return;
          const ni = {...val};
          ni[thisStep.field] = v.name;
          ni[thisStep.field + 'Index'] = v.index;
          setVal(ni);
        }}
        style={{ width: 300 }}
        renderInput={params => <TextField
                                 {...params}
                                 margin="dense" fullWidth
                               />}
      />
      </Grid>
    );
  };

  const renderText = () => {
    const thisStep = spec.steps[step];
    const value = val[thisStep.field] ? val[thisStep.field] : ''; // Rewind logic
    return (
      <Grid item md={12} lg={6}>
        <TextField required label="" type="text"
                   value={value} autoFocus={true}
                   variant="outlined" size="small"
                   error={false} helperText=""
                   fullWidth
                   onChange={e => {
                     const ni = {...val};
                     ni[thisStep.field] = e.target.value;
                     setVal(ni);
                   }}
        />
      </Grid>
    );
  };

  const renderButtonGroup = () => {
    const thisStep = spec.steps[step];
    const savedIdx = val[thisStep.field + 'Index']; // Rewind logic
    const btnIdx = savedIdx !== undefined ? savedIdx : -1;        // Rewind logic
    return (
      <div>
      {thisStep.params.map((x, idx) => {
        const bdrColor = idx === btnIdx ? 'black' : 'white';
        return (
          <Button
            key={idx}
            size="small"
            style={{backgroundColor: x.color,  color: 'white', margin: 5,
                    borderColor: bdrColor, borderWidth: 2, borderStyle: 'solid'
                   }}
            onClick={() => {
              const ni = {...val};
              ni[thisStep.field] = x.name;
              ni[thisStep.field + 'Index'] = idx;
              setVal(ni);
            }}
          >
            {x.name.toUpperCase()}
          </Button>
       );
      })}
      </div>
    );
 };

  const renderStar = () => {
    const thisStep = spec.steps[step];
    const inWords = ['', 'Unacceptable', 'Poor', 'Average', 'Good', 'Excellent'];
    const value = val[thisStep.field] ? val[thisStep.field] : 0; // Rewind logic
    return (
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Ratings
          rating={value}
          widgetDimensions="40px"
          widgetSpacings="15px"
          widgetRatedColors="orange"
          widgetEmptyColors="grey"
          changeRating={(newValue) => {
            const ni = {...val};
            ni[thisStep.field] = newValue;
            setVal(ni);
          }}
        >
          <Ratings.Widget />
          <Ratings.Widget />
          <Ratings.Widget />
          <Ratings.Widget />
          <Ratings.Widget />
        </Ratings>
        <div>
          <Typography variant="body1" style={{color: 'grey'}}>{inWords[value]}</Typography>
        </div>
      </Grid>
    );
  };

  const renderEmail = () => {
    const thisStep = spec.steps[step];
    const value = val[thisStep.field] ? val[thisStep.field] : ''; // Rewind logic
    return (
      <Grid item md={12} lg={6}>
        <EmailInput
          helperText=" "
          retVal={ret => {
            const ni = {...val};
            ni[thisStep.field] = ret.value;
            setVal(ni);
          }}
        />
      </Grid>
    );
  };

  const renderMultilineText = () => {
    const thisStep = spec.steps[step];
    const value = val[thisStep.field] ? val[thisStep.field] : ''; // Rewind logic
    return (
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <TextField label="" type="text"
                   value={value}
                   variant="outlined"
                   error={false} helperText=""
                   multiline rows={4}
                   fullWidth
                   onChange={e => {
                     const ni = {...val};
                     ni[thisStep.field] = e.target.value;
                     setVal(ni);
                   }}
        />
      </Grid>
    );
  };

  const renderStep = () => {
    const sMap = {
      buttonGroup: renderButtonGroup,
      autocomplete: renderAutocomplete,
      text: renderText,
      star: renderStar,
      email: renderEmail,
      multilineText: renderMultilineText
    };
    const thisStep = spec.steps[step];
    const nxtText = (step === spec.steps.length -1) || (thisStep.stop) ? "Submit" : "Next";
    const nxtIcon = nxtText === "Next" ? <ArrowForwardIcon /> : <DoneIcon/>;
    const nxtAction = () => {
      // console.log(stack);
      if (thisStep.nxtAction) {
        const r = thisStep.nxtAction(val);
        if (r.error) {
          enqueueSnackbar(r.message, {variant: 'error'});
          return;
        }
      }
      if (nxtText === "Next") {
        const nextStep = thisStep.goto ? thisStep.goto(val) : step + 1;
        const s = [...stack]; // Push step onto stack
        s.push(step);         // Push step onto stack
        setStack(s);          // Push step onto stack
        if (nextStep > step && nextStep < spec.steps.length) {
          setStep(nextStep);    // Goto next step
        } else {
          setStep(step + 1);    // Goto next step
        }
      } else {
        spec.onSubmit && spec.onSubmit(val);
        setStep(firstStep);
        setVal({});
        setStack([]);
      }
    };

    const prevAction = () => {
      // console.log(stack);
      // Pop step from stack
      const prevStep = stack[stack.length - 1];
      const s = [...stack];
      s.splice(s.length - 1, 1);
      setStack(s);
      if (prevStep === -1) {
        setVal({});
      }
      setStep(prevStep);
    };

    return (
      <Paper style={styles.paper}>
        <Typography variant="h6">{thisStep.title}</Typography>
        <Grid container direction="row" style={styles.body}>
          {sMap[thisStep.type]()}
        </Grid>
        <Divider />
        <Grid container direction="row" style={styles.direction}>
          {step !== firstStep &&
           <Grid item>
             <Button aria-label="close" color="primary" variant="outlined"
                     size="small" startIcon={<ArrowBackIcon/>}
                     onClick={() => prevAction()}
             >
               Back
             </Button>
           </Grid>
          }
          <Grid item>
            <Button aria-label="next" color="primary" variant="outlined"
                    startIcon={nxtIcon}
                    style={{marginLeft: 10}}
                    disabled={false}
                    size="small"
                    onClick={() => nxtAction()}
            >
              {nxtText}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  const renderButton = () => {
    const buttonBg = spec.buttonColor ? spec.buttonColor : '#5f5fd3';
    return (
      <Button
        style={{marginLeft: 13, backgroundColor: buttonBg, height: 40}}
        color="primary" variant="contained" aria-label="add"
        onClick={() => {
          setStack([-1]);
          setStep(0);
        }}
      >
        <AddIcon />
        {spec.title}
      </Button>
    );
  };

  return (
    <div style={styles.container}>
      {step === -1 && renderButton()}
      {step >= 0 && renderStep()}
    </div>
  );

}
