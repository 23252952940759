import React from 'react';

import Container from '@material-ui/core/Container';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Analytics from '../components/Analytics';

export default function AnalyticsLayout() {
  const [tabIdx, setTabIdx] = React.useState(0);

  const tabItems = [
    { title: 'Analytics', component: <Analytics /> },
  ];
  const renderTabs = () => (
    <Tabs
      value={tabIdx}
      onChange={(e, v) => setTabIdx(v)}
      indicatorColor="primary"
      textColor="primary"
      centered
    >
      {tabItems.map((x) => <Tab label={x.title} key={x.title} />)}
    </Tabs>
  );

  const renderContents = () => (tabItems[tabIdx].component);

  return (
    <Container
      maxWidth="lg"
      style={{ marginTop: 20 }}
    >
      {renderTabs()}
      {renderContents()}
    </Container>
  );
}
