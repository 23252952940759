/* eslint-disable no-console, react/prop-types */
import React, { Fragment, useState } from 'react';

import { Chart } from 'react-google-charts';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import ToggleButton from './ToggleButton';
import * as Utils from '../utils';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    margin: 3,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  dataText: {
    color: '#4d4d4d',
    fontSize: 'small',
  },
  tableTitle: {
    fontSize: 'small',
  },
  largeText: {
    fontSize: 'x-large',
  },
});

export default function PipelineStage(props) {
  const { stage, index } = props;
  const dat = props.data;
  const classes = useStyles();
  const [showGraph, setShowGraph] = useState(true);

  const renderTitle = () => (
    <Typography variant="h6" component="h2">
      {`${index + 1}. ${stage.title}`}
    </Typography>
  );

  const renderSubtitle = () => {
    const firstStage = stage.states[0];
    const lastStage = stage.states[stage.states.length - 1];
    return (
      <Typography className={classes.pos} color="textSecondary">
        {`From '${firstStage}' to '${lastStage}'`}
      </Typography>
    );
  };

  const printablePeriod = (ts) => {
    const d = new Date(ts);
    return Utils.monthNames[d.getMonth()].substring(0, 3);
    // return `${d.getDate()}-${Utils.monthNames[d.getMonth()]}`;
  };

  const getStatsForPeriod = (periodData) => {
    const input = periodData[stage.states[0]];
    const output = periodData[stage.states[stage.states.length - 1]];
    let conversion = input > 0 ? Math.floor((output * 100) / input) : 0;
    conversion = (input === 0 && output > 0) ? Math.floor((output * 100) / 1) : conversion;
    const results = [printablePeriod(periodData.start), input, output, conversion];
    return results;
  };

  const renderStatsForPeriod = (periodData) => {
    const results = getStatsForPeriod(periodData);
    return (
      <>
        {results.map((x, idx) => (
          <Grid item xs={3} key={`${x}-${idx}`}>
            <Typography className={classes.dataText}>
              {x}
            </Typography>
          </Grid>
        ))}
      </>
    );
  };

  const renderTable = () => {
    if (!dat || dat.length === 0) {
      return null;
    }
    const titles = ['period', stage.states[0], stage.states[stage.states.length - 1], 'conversion'];
    return (
      <Grid container direction="row">
        {titles.map((t) => (
          <Grid item xs={3} key={t}>
            <Typography className={classes.tableTitle} color="textPrimary">
              {t}
            </Typography>
          </Grid>
        ))}
        {dat.map((x) => renderStatsForPeriod(x))}
      </Grid>
    );
  };

  const renderGraph = () => {
    if (!dat || dat.length === 0) {
      return null;
    }
    const results = dat.map((x) => getStatsForPeriod(x));
    /*
    const d = [['Period', stage.states[0], stage.states[stage.states.length - 1]]];
    results.forEach((r) => d.push([r[0], r[1], r[2]]));
    */
    const d = [['Period', 'Conversion %']];
    results.forEach((r) => d.push([r[0], r[3]]));
    console.log(d);
    return (
      <Chart
        chartType="LineChart"
        width="100%"
        height="200px"
        data={d}
      />
    );
  };

  const renderStage = () => (
    <Card className={classes.root}>
      <CardContent>
        {renderTitle(stage, index)}
        {renderSubtitle(stage)}
        {!showGraph && renderTable(stage)}
        {showGraph && renderGraph(stage)}
      </CardContent>
      <CardActions>
        <ToggleButton
          titleA="Show Data"
          titleB="Show Graph"
          onClick={() => setShowGraph(!showGraph)}
        />
      </CardActions>
    </Card>
  );

  return renderStage();
}
