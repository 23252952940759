/* eslint-disable no-console, react/prop-types */
import React, { useState } from 'react';

import Button from '@material-ui/core/Button';

export default function ToggleButton(props) {
  const { titleA, titleB, onClick } = props;
  const [title, setTitle] = useState(titleA);
  return (
    <Button
      size="small"
      variant="contained"
      style={{ background: '#5fd38d' }}
      onClick={() => {
        if (onClick) {
          onClick(title);
        }
        if (title === titleA) {
          setTitle(titleB);
        } else {
          setTitle(titleA);
        }
      }}
    >
      {title}
    </Button>
  );
}
