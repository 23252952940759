/* eslint-disable no-param-reassign */
import * as Utils from './utils';

const ProfileStates = [
  {
    title: 'all',
    color: '#5f5fd3',
    entry: {
      capture: [],
    },
    next: [],
  },
  {
    title: 'inbox',
    color: '#db3ee8',
    icon: '',
    entry: {
      capture: [],
    },
    next: [
      'shortlisted',
      'unsuitable',
    ],
  },
  {
    title: 'shortlisted',
    color: '#e8da3e',
    icon: '',
    entry: {
      onEntry: () => {},
      onSubmit: () => {},
      capture: [
        {
          field: 'company',
          label: 'Company',
          type: 'autocomplete',
          params: [],
        },
        {
          field: 'role',
          label: 'Role',
          type: 'autocomplete',
          params: [],
        },
        // { field: 'interview', label: 'Interview', type: 'date' },
      ],
    },
    next: ['qualified', 'scheduled', 'unsuitable', 'rejected', 'uninterested', 'hold'],
  },
  {
    title: 'qualified',
    color: '#e7e83e',
    icon: '',
    entry: {
      capture: [],
    },
    next: ['scheduled', 'uninterested', 'rejected', 'hold'],
  },
  {
    title: 'scheduled',
    color: '#c0e83e',
    icon: '',
    entry: {
      capture: [
        { field: 'interviewDate', label: 'Interview date', type: 'date' },
      ],
    },
    next: ['interviewed', 'rejected', 'dropped', 'hold'],
  },
  {
    title: 'interviewed',
    color: '#8ce83e',
    icon: '',
    entry: {
      capture: [],
    },
    next: ['selected', 'uninterested', 'rejected'],
  },
  {
    title: 'selected',
    color: '#3ee843',
    icon: '',
    entry: {
      capture: [],
    },
    next: ['offered', 'dropped'],
  },
  {
    title: 'offered',
    color: '#00d4aa',
    icon: '',
    entry: {
      capture: [
        { field: 'offerDate', label: 'Offer date', type: 'date' },
        { field: 'joiningDate', label: 'Joining date', type: 'date' },
      ],
    },
    next: ['joined', 'dropped'],
  },
  {
    title: 'joined',
    color: '#00ccff',
    icon: '',
    entry: {
      capture: [
        { field: 'joinDate', label: 'Joined date', type: 'date' },
        { field: 'newRole', label: 'Offered role', type: 'text' },
        { field: 'newCtc', label: 'CTC in lakhs', type: 'number' },
      ],
    },
    next: ['invoiced', 'dropped'],
  },
  {
    title: 'invoiced',
    color: '#00ccff',
    icon: '',
    entry: {
      capture: [],
    },
    next: ['dropped'],
  },
  {
    title: 'hold',
    color: '#00ccff',
    icon: '',
    entry: {
      capture: [
        { field: 'holdReason', label: 'Reason for putting on hold', type: 'text' },
      ],
    },
    next: ['shortlisted'],
  },
  {
    title: 'unsuitable',
    color: '#e85f3e',
    icon: '',
    entry: {
      capture: [
        { field: 'reason', label: 'Reason', type: 'text' },
      ],
    },
    next: [],
  },
  {
    title: 'rejected',
    color: '#e85f3e',
    icon: '',
    entry: {
      capture: [
        { field: 'rejectReason', label: 'Reason for rejection', type: 'text' },
      ],
    },
    next: [],
  },
  {
    title: 'uninterested',
    color: '#7c6f91',
    icon: '',
    entry: {
      capture: [
        { field: 'reason', label: 'Reason', type: 'text' },
      ],
    },
    next: [],
  },
  {
    title: 'dropped',
    color: '#7c6f91',
    icon: '#808080',
    entry: {
      capture: [
        { field: 'reason', label: 'Reason for dropping', type: 'text' },
      ],
    },
    next: [],
  },
];

const updateProfile = (profileId, record) => window
  .firestore
  .collection('data')
  .doc(global.role.orgId)
  .collection('profiles')
  .doc(profileId)
  .set(record, { merge: true });

const appendHistory = (profileId, message, prevState, state) => {
  const time = Utils.timestamp();
  window
    .firestore
    .collection('data')
    .doc(global.role.orgId)
    .collection('history')
    .doc(profileId)
    .set({
      [time]: {
        time,
        authorId: global.role.appUid,
        authorOrg: global.role.orgId,
        message,
        prevState,
        state,
      },
    }, { merge: true });
};

// For each type of captured field, generate a comment
const commentMap = {
  text: (capture, val) => (`${capture.label}: ${val[capture.field]}`),
  number: (capture, val) => (`${capture.label}: ${val[capture.field]}`),
  date: (capture, val) => (`${capture.label}: ${Utils.printDate(new Date(val[capture.field]))}`),
  autocomplete: (capture, val) => (`${capture.label}: ${val[capture.field]}`),
};
// For each type of captured field, save field in record
const recordMap = {
  text: (capture, val, record) => { record[capture.field] = val[capture.field]; },
  number: (capture, val, record) => { record[capture.field] = val[capture.field]; },
  date: (capture, val, record) => { record[capture.field] = val[capture.field]; },
  autocomplete: (capture, val, record) => { record[capture.field] = val[capture.field]; },
};

const getProfileStates = () => {
  const ps = [...ProfileStates];
  if (!global.Store) {
    return ps;
  }
  const store = global.Store.get();
  const state = store.getState();

  ps.forEach((slot) => {
    // eslint-disable-next-line
    slot.entry.onSubmit = (val, row) => {
      // Init rec
      const rec = {
        state: slot.title,
        prevState: row.state,
      };
      // Generate comment by iterating over each capture entry
      const cmt = slot
        .entry
        .capture.map((x) => commentMap[x.type](x, val))
        .reduce(
          (acc, cur) => (`${acc}; ${cur}`),
          '',
        );
      rec.comment = `--> ${slot.title}; ${cmt}`;
      // Generate fields to save in rec by iterating over each capture entry
      slot.entry.capture.forEach((x) => recordMap[x.type](x, val, rec));
      // console.log(rec);
      updateProfile(row.profileId, rec);
      appendHistory(row.profileId, rec.comment, rec.prevState, rec.state);
    };
  });

  const shortlisted = ps.find((x) => x.title === 'shortlisted');
  shortlisted.entry.capture[0].params = () => state.firestore.companies.sort();
  shortlisted.entry.capture[1].params = (val) => {
    if (val.company) {
      const co = state.firestore.companies.find((x) => x.name === val.company);
      return state.firestore.jds
        .filter((x) => x.companyId === co.companyId)
        .sort((a, b) => ((a.name < b.name) ? -1 : 1));
    }
    return [];
  };
  shortlisted.entry.onSubmit = (val, row) => {
    const co = state.firestore.companies.find((x) => x.name === val.company);
    const role = state.firestore.jds.find((x) => x.name === val.role);
    const rec = {
      state: 'shortlisted',
      prevState: row.state,
      company: co.name,
      companyId: co.companyId,
      role: role.name,
      roleId: role.id,
      comment: `--> shortlisted; for ${co.name} for ${role.name}`,
    };
    updateProfile(row.profileId, rec);
    appendHistory(row.profileId, rec.comment, rec.prevState, rec.state);
  };
  return ps;
};

export default getProfileStates;
