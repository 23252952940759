import React from 'react';
import TextField from '@material-ui/core/TextField';

export default function EmailInput(props) {
  const [error, setError] = React.useState(true);
  const isValidEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const helperText = props.helperText ? props.helperText : "Candidate's email";
  return (
    <TextField required label="email id" type="email" size="small"
               error={error} variant="outlined" helperText={helperText}
               onChange={e => {
                 const valid = isValidEmail(e.target.value);
                 setError(!valid);
                 props.retVal && props.retVal({error: !valid, value: e.target.value});
               }}
    />
  );
}
