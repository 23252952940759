import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import Dashboard from './layouts/Dashboard';
import Login from './components/Login';
import Test from './components/MyndForm';
import store from './redux/store';

const hist = createBrowserHistory();
global.Store.init();

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    window.isSignedIn === true
      ? <Component {...props} />
      : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
  )}
  />
);

ReactDOM.render(
  <SnackbarProvider>
    <Provider store={store}>
      <Router history={hist}>
        <Switch>
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <Route path="/login" component={Login} />
          <Route path="/test" component={Test} />
          <Redirect from="/" to="/dashboard" />
        </Switch>
      </Router>
    </Provider>
  </SnackbarProvider>,
  document.getElementById('root'),
);
