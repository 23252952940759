/* eslint-disable no-console, react/prop-types */
import React, { useState } from 'react';

import { Chart } from 'react-google-charts';
import MaterialTable from 'material-table';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import ToggleButton from './ToggleButton';
import * as Utils from '../utils';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    margin: 3,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  dataText: {
    color: '#4d4d4d',
    fontSize: 'small',
  },
  tableTitle: {
    fontSize: 'medium',
  },
});

const statesToDisplay = ['inbox', 'qualified', 'interviewed', 'joined'];

export default function AllStages(props) {
  const dat = props.data;
  const classes = useStyles();
  const [showGraph, setShowGraph] = useState(true);

  const renderTitle = () => (
    <Typography variant="h6" component="h2">
      Funnel
    </Typography>
  );

  const renderSubtitle = () => {
    const firstStage = statesToDisplay[0];
    const lastStage = statesToDisplay[statesToDisplay.length - 1];
    return (
      <Typography className={classes.pos} color="textSecondary">
        {`From '${firstStage}' to '${lastStage}'`}
      </Typography>
    );
  };

  const printablePeriod = (ts) => {
    const d = new Date(ts);
    const month = Utils.monthNames[d.getMonth()].substring(0, 3);
    const year = d.getFullYear().toString().substring(2, 4);
    return `${month} '${year}`;
  };

  const getTitles = () => {
    const titles = ['period'];
    statesToDisplay.forEach((x) => titles.push(x));
    return titles;
  };

  const getStatsForPeriod = (periodData, options) => {
    if (options && options.asMap) {
      const results = { period: printablePeriod(periodData.start) };
      statesToDisplay.forEach((x) => {
        results[x] = periodData[x];
      });
      return results;
    }
    const results = [printablePeriod(periodData.start)];
    statesToDisplay.forEach((x) => results.push(periodData[x]));
    return results;
  };

  const renderTable = () => {
    if (!dat || dat.length === 0) {
      return null;
    }
    const cols = getTitles().map((x) => ({
      title: x,
      field: x,
    }));
    const data = dat.map((x) => getStatsForPeriod(x, { asMap: true }));
    const options = {
      padding: 'dense',
      pageSizeOptions: [5, 10, 20],
      pageSize: 10,
    };
    return (
      <MaterialTable
        columns={cols}
        data={data}
        options={options}
        title=""
      />
    );
  };

  const renderGraph = () => {
    if (!dat || dat.length === 0) {
      return null;
    }
    const results = dat.map((x) => getStatsForPeriod(x));
    const titles = getTitles();
    const d = [titles];
    results.forEach((r) => d.push(r));
    return (
      <Chart
        chartType="Bar"
        width="100%"
        height="300px"
        data={d}
      />
    );
  };

  const render = () => (
    <Card className={classes.root}>
      <CardContent>
        {renderTitle()}
        {renderSubtitle()}
        {!showGraph && renderTable()}
        {showGraph && renderGraph()}
      </CardContent>
      <CardActions>
        <ToggleButton
          titleA="Show Data"
          titleB="Show Graph"
          onClick={() => setShowGraph(!showGraph)}
        />
      </CardActions>
    </Card>
  );

  return render();
}
